import {useEffect, useState} from 'react';

interface UseCountdownProps {
  initialSeconds: number;
  onFinish?: () => void;
}

export const useCountdown = ({initialSeconds, onFinish}: UseCountdownProps) => {
  const [countdown, setCountdown] = useState(initialSeconds);

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => {
        setCountdown(countdown - 1);
        if (countdown === 1 && onFinish) {
          onFinish();
        }
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [countdown, onFinish]);

  const startCountdown = (seconds = initialSeconds) => {
    setCountdown(seconds);
  };

  const resetCountdown = () => {
    setCountdown(0);
  };

  return {
    countdown,
    startCountdown,
    resetCountdown,
    isActive: countdown > 0
  };
};
