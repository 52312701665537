import {LockOutlined} from '@ant-design/icons/lib';
import {Button, Card} from 'antd';
import {Auth} from 'aws-amplify';
import {FormikProps, withFormik} from 'formik';
import {Checkbox, Form, FormItem, Input} from 'formik-antd';
import {useCountdown} from 'hooks/useCountdown';
import React, {useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {AuthState, IAuthProps, MfaType} from 'types';
import * as yup from 'yup';
import {AnchorLink} from '../AnchorLink';
import {Description, Extra} from './Shared';

interface IFormValues {
  code: string;
  remember: boolean;
}

const C: React.FC<FormikProps<IFormValues> & IAuthProps> = ({
  isSubmitting,
  setState,
  mfaDestination,
  email,
  password,
  mfaType,
  setFieldError
}) => {
  const {t} = useTranslation();
  const [isResending, setIsResending] = useState(false);
  const {countdown, startCountdown, resetCountdown, isActive} = useCountdown({
    initialSeconds: 60
  });

  const backToSignIn = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setState({authState: AuthState.signIn, email});
  };

  const resendCode = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    startCountdown();
    try {
      setIsResending(true);
      await Auth.signIn(email!, password);
    } catch (error: any) {
      resetCountdown();
      setFieldError('code', error.message);
    } finally {
      setIsResending(false);
    }
  };

  return (
    <Card
      title={t('Two-step authentication')}
      extra={<AnchorLink onClick={backToSignIn}>{t('Back to Sign In')}</AnchorLink>}>
      <Description>
        {mfaType === MfaType.SMS_MFA ? (
          <Trans>
            To continue, please enter the 6-digit verification code sent to{' '}
            <strong>{{mfaDestination}}</strong>.
          </Trans>
        ) : (
          <Trans>
            To continue, please enter the 6-digit verification code generated by your{' '}
            <strong>authenticator app</strong>.
          </Trans>
        )}
      </Description>
      <Form>
        <FormItem name="code">
          <Input
            name="code"
            type="text"
            size="large"
            autoComplete="one-time-code"
            prefix={<LockOutlined />}
            placeholder={t('Verification code')}
          />
        </FormItem>
        <p>
          <Checkbox name="remember">
            <Trans>Remember this device?</Trans>
          </Checkbox>
        </p>
        <Button block size="large" type="primary" loading={isSubmitting} htmlType="submit">
          {t('Confirm')}
        </Button>
        <Extra>
          <Trans key="Lost your code?">
            Lost your code?{' '}
            <AnchorLink 
              onClick={resendCode} 
              disabled={isResending || isActive}
            >
              {isActive ? `Resend Code (${countdown}s)` : 'Resend Code'}
            </AnchorLink>
          </Trans>
        </Extra>
      </Form>
    </Card>
  );
};

export const ConfirmSignIn = withFormik<IAuthProps, IFormValues>({
  validationSchema: () =>
    yup.object().shape({
      code: yup.string().required()
    }),
  mapPropsToValues: ({code = ''}) => ({code, remember: true}),
  handleSubmit: async ({code, remember}, {props, setErrors, setSubmitting}) => {
    try {
      await Auth.confirmSignIn(props.currentUser, code, props.mfaType);
      if (remember) await Auth.rememberDevice();
      const currentUser = await Auth.currentAuthenticatedUser();
      props.setState({authState: AuthState.signedUp, currentUser});
    } catch (error: any) {
      setErrors({code: error.message});
    } finally {
      setSubmitting(false);
    }
  }
})(C);
