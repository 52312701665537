import gql from 'graphql-tag';

export const addressFields = gql`
  fragment AddressFields on Address {
    city
    country
    line1
    line2
    zip
    state
  }
`;

export const liveAccountFields = gql`
  fragment LiveAccountFields on Account {
    id
    status
    timezone
    previewFeatures
  }
`;

export const accountFields = gql`
  fragment AccountFields on Account {
    id
    name
    timezone
    language
    defaultCurrency
    billingPlan
    masterAccountId
    promoCode
    blocked
    publicBusinessDetails {
      companyName
      website
      phone
      address {
        ...AddressFields
      }
      supportWebsite
      supportEmail
      supportPhone
    }
    branding {
      accentColor
      bgColor
      logoUrl
      iconUrl
    }
    payoutTerms {
      iban
      period
      reservePercent
      firstPeriod
      suspended
    }
    business {
      country
      legalForm
      legalName
      companyName
      address {
        ...AddressFields
      }
      mcc
      allowedMcc
      documentNumber
      email
      phone
      website
      confirmWebsite
      industry
      servicesDescription
      confirmServicesDescription
      status
    }
    bankAccount {
      status
    }
    representatives {
      firstName
      lastName
      address {
        ...AddressFields
      }
      email
      phone
      documentNumber
      citizenship
      status
      statusCode
      statusMessage
      hasNoPublicActivity
    }
    iban
    customDomain
    customDomainConfig {
      domain
      certificateDnsRecord {
        name
        type
        value
      }
      distributionDnsRecord {
        name
        type
        value
      }
      status
    }
    notifications {
      emailCustomerOnRefund
      emailCustomerOnSuccess
      emailAdminOnSuccess
      emailCustomerOnSubscription
      emailAdminOnSubscription
    }
    tokenizationMethods {
      googlePay {
        enabled
      }
      applePay {
        enabled
      }
      clickToPay {
        enabled
      }
    }
    test
    status
    apiKey
    referralCode
    promoCode
    statusReason
    statusNote
    contract {
      envelopeId
      status
      path
      signedDate
    }
    adminEmail
    userNote
    approvedAt
  }
  ${addressFields}
`;

export const authorizedUserFields = gql`
  fragment AuthorizedUserFields on AuthorizedUser {
    userEmail
    id
    accessLevel
    createdAt
    updatedAt
    lastLoginAt
    status
    storeId
  }
`;

export const sessionDetailsFields = gql`
  fragment SessionDetailsFields on SessionDetails {
    ip
    userAgent
    countryCode
    lang
    deviceType
    deviceModel
    browser
    browserVersion
    browserAccept
    browserColorDepth
    browserScreenHeight
    browserScreenWidth
    browserTimezoneOffset
    os
    osVersion
    source
    sourceVersion
  }
`;

export const traceDetailsFields = gql`
  fragment TraceDetailsFields on TraceDetails {
    ip
    userAgent
    countryCode
    lang
    deviceType
    deviceModel
    browser
    browserVersion
    browserAccept
    os
    osVersion
    source
    sourceVersion
    userId
    userEmail
  }
`;

export const paymentMethodFields = gql`
  fragment PaymentMethodFields on PaymentMethod {
    method
    card {
      brand
      country
      type
      threeDSecure
      threeDSecureVersion
      threeDSecureFlow
      last4
      cardholderName
      cardholderEmail
      expiration
      bank
      tokenizationMethod
    }
    bizum {
      phoneNumber
      integrationType
    }
    paypal {
      orderId
      payerId
      email
      name
    }
    cofidis {
      orderId
    }
    cofidisLoan {
      orderId
    }
    trustly {
      customerId
    }
    sepa {
      accountholderAddress {
        city
        country
        line1
        line2
        zip
        state
      }
      accountholderEmail
      accountholderName
      countryCode
      bankAddress
      bankCode
      bankName
      bic
      last4
    }
    klarna {
      billingCategory
      authPaymentMethod
    }
    mbway {
      phoneNumber
    }
  }
`;

export const chargeFields = gql`
  fragment ChargeFields on Charge {
    id
    accountId
    sequenceId
    subscriptionId
    pointOfSaleId
    providerReferenceId
    createdAt
    updatedAt
    amount
    authorizationCode
    storeId
    billingDetails {
      email
      name
      company
      phone
      address {
        ...AddressFields
      }
    }
    currency
    customer {
      email
      name
      phone
    }
    description
    descriptor
    livemode
    orderId
    paymentMethod {
      ...PaymentMethodFields
    }
    refundedAmount
    lastRefundAmount
    lastRefundReason
    cancellationReason
    shippingDetails {
      email
      name
      company
      phone
      address {
        ...AddressFields
      }
    }
    shop {
      name
      country
    }
    status
    statusCode
    statusMessage
    sessionDetails {
      ...SessionDetailsFields
    }
    traceDetails {
      ...TraceDetailsFields
    }
  }
  ${addressFields}
  ${sessionDetailsFields}
  ${traceDetailsFields}
  ${paymentMethodFields}
`;

export const chargeEventObjectFields = gql`
  fragment ChargeEventObjectFields on ChargeEventObject {
    id
    accountId
    sequenceId
    subscriptionId
    providerReferenceId
    createdAt
    updatedAt
    amount
    authorizationCode
    billingDetails {
      email
      name
      company
      phone
      address {
        ...AddressFields
      }
    }
    currency
    customer {
      email
      name
      phone
    }
    description
    livemode
    orderId
    paymentMethod {
      ...PaymentMethodFields
    }
    refundedAmount
    lastRefundAmount
    lastRefundReason
    cancellationReason
    shippingDetails {
      email
      name
      company
      phone
      address {
        ...AddressFields
      }
    }
    shop {
      name
      country
    }
    status
    statusCode
    statusMessage
    sessionDetails {
      ...SessionDetailsFields
    }
    traceDetails {
      ...TraceDetailsFields
    }
  }
  ${addressFields}
  ${sessionDetailsFields}
  ${traceDetailsFields}
  ${paymentMethodFields}
`;

export const paymentFields = gql`
  fragment PaymentFields on Payment {
    id
    accountId
    sequenceId
    subscriptionId
    pointOfSaleId
    createdAt
    updatedAt
    amount
    authorizationCode
    storeId
    billingDetails {
      email
      name
      company
      phone
      address {
        ...AddressFields
      }
    }
    currency
    customer {
      email
      name
      phone
    }
    description
    descriptor
    livemode
    orderId
    paymentMethod {
      ...PaymentMethodFields
    }
    refundedAmount
    lastRefundAmount
    lastRefundReason
    cancellationReason
    shippingDetails {
      email
      name
      company
      phone
      address {
        ...AddressFields
      }
    }
    shop {
      name
      country
    }
    status
    statusCode
    statusMessage
    sessionDetails {
      ...SessionDetailsFields
    }
    traceDetails {
      ...TraceDetailsFields
    }
    nextAction {
      type
      redirectUrl
      mustRedirect
    }
  }
  ${addressFields}
  ${sessionDetailsFields}
  ${traceDetailsFields}
  ${paymentMethodFields}
`;

export const providerFields = gql`
  fragment ProviderFields on Provider {
    id
    processor
    enabled
    paymentMethod
    acquirer
    configurable
    metadata {
      key
      value
      type
      label
      possibleValues
      regex
      required
      secret
      example
      default
      internal
    }
    rules {
      minAmount
      maxAmount
      shopNameLike
      descriptionLike
    }
  }
`;

export const accountInvoiceFields = gql`
  fragment AccountInvoiceFields on AccountInvoice {
    id
    number
    accountId
    period {
      from
      to
    }
    currency
    amount
    tax
    totalAmount
    lineItems {
      name
      quantity
      unitAmount
      unitType
      currency
      amount
    }
    business {
      legalName
      iban
      documentNumber
      mandateSignatureTimestamp
    }
    referralCode
    createdAt
    status
    paymentMethod
  }
`;

export const accountSettlementFields = gql`
  fragment AccountSettlementFields on AccountSettlement {
    accountId
    acquirerFees
    grossVolume
    iban
    id
    legalName
    netVolume
    payMethod
    periodEndAt
    periodStartAt
    releasedAmount
    reservedAmount
    scheduledAt
    status
    storeId
    settleGross
  }
`;

export const storeFields = gql`
  fragment StoreFields on Store {
    id
    name
    email
    phone
    iban
    website
    address {
      ...AddressFields
    }
  }
  ${addressFields}
`;

export const documentFields = gql`
  fragment DocumentFields on Document {
    id
    name
    note
    type
    status
    statusCode
    statusMessage
    contentType
    createdAt
    updatedAt
  }
`;

export const contactFields = gql`
  fragment ContactFields on ContactDetails {
    email
    name
    company
    phone
    taxId
    address {
      ...AddressFields
    }
  }
  ${addressFields}
`;
export const subscriptionFields = gql`
  fragment SubscriptionFields on Subscription {
    id
    accountId
    livemode
    amount
    currency
    description
    cancelAtPeriodEnd
    pauseIntervalCount
    pauseAtPeriodEnd
    currentPeriodStart
    currentPeriodEnd
    trialPeriodDays
    trialPeriodEnd
    customerId
    customer {
      email
      name
      phone
    }
    billingDetails {
      ...ContactFields
    }
    shippingDetails {
      ...ContactFields
    }
    sequenceId
    lastOrderId
    lastPayment {
      id
      status
      statusMessage
      statusCode
    }
    paymentMethod {
      ...PaymentMethodFields
    }
    nextPaymentAt
    createdAt
    updatedAt
    interval
    intervalCount
    retryCount
    status
    traceDetails {
      ...TraceDetailsFields
    }
    sessionDetails {
      ...SessionDetailsFields
    }
    callbackUrl
    paymentCallbackUrl
    storeId
  }
  ${contactFields}
  ${paymentMethodFields}
  ${traceDetailsFields}
  ${sessionDetailsFields}
`;

export const lastPaymentFields = gql`
  fragment LastPaymentFields on LastPayment {
    id
    status
    statusMessage
    statusCode
  }
`;

export const subscriptionEventObjectFields = gql`
  fragment SubscriptionEventObjectFields on SubscriptionEventObject {
    id
    accountId
    amount
    billingDetails {
      email
      name
      company
      phone
      address {
        ...AddressFields
      }
    }
    cancelAtPeriodEnd
    createdAt
    currency
    currentPeriodEnd
    currentPeriodStart
    customer {
      email
      name
      phone
    }
    description
    interval
    intervalCount
    lastOrderId
    lastPayment {
      ...LastPaymentFields
    }
    livemode
    pauseAtPeriodEnd
    pauseIntervalCount
    paymentMethod {
      ...PaymentMethodFields
    }
    pointOfSaleId
    retryCount
    sequenceId
    shippingDetails {
      email
      name
      company
      phone
      address {
        ...AddressFields
      }
    }
    status
    storeId
    traceDetails {
      ...TraceDetailsFields
    }
    trialPeriodDays
    trialPeriodEnd
    updatedAt
  }
  ${addressFields}
  ${sessionDetailsFields}
  ${traceDetailsFields}
  ${paymentMethodFields}
  ${lastPaymentFields}
`;

export const webhookFields = gql`
  fragment WebhookFields on Webhook {
    id
    accountId
    url
    enabled
    eventTypes
    secret
    createdAt
    updatedAt
  }
`;

export const startExportChargesFields = gql`
  fragment StartExportChargesFields on ChargesExport {
    id
    status
  }
`;
