import holded from 'images/partners/holded.svg';
import yumminn from 'images/partners/yumminn.svg';
import honei from 'images/partners/honei.svg';
import glop from 'images/partners/glop.png';
import kevo from 'images/partners/kevo.png';
import taxitronic from 'images/partners/taxitronic.png';
import qamarero from 'images/partners/qamarero.png';
import paymefy from 'images/partners/paymefy.png';
import comercia from 'images/partners/comercia.svg';
import hotelgest from 'images/partners/hotelgest.png';
import truust from 'images/partners/truust.svg';
import lymon from 'images/partners/lymon.png';
import mrm from 'images/partners/mrm.png';
import payandtip from 'images/partners/payandtip.jpg';
import qs from 'query-string';
import React, {useMemo} from 'react';

type Partner = {
  name: string;
  logo: string;
  logoStyle?: React.CSSProperties;
  logoSmallStyle?: React.CSSProperties;
  url: string;
  code?: string | null;
  visible?: boolean;
};

const partners: Record<string, Partner> = {
  holded: {
    name: 'Holded',
    logo: holded,
    logoStyle: {
      height: 30
    },
    logoSmallStyle: {
      height: 18
    },
    url: 'https://www.holded.com/',
    visible: true
  },
  yumminn: {
    name: 'Yumminn',
    logo: yumminn,
    logoStyle: {
      height: 35
    },
    logoSmallStyle: {
      height: 20
    },
    url: 'https://yumminn.com/',
    visible: true
  },
  honei: {
    name: 'Honei',
    logo: honei,
    logoStyle: {
      height: 30
    },
    logoSmallStyle: {
      height: 18
    },
    url: 'https://honei.app/',
    visible: true
  },
  glop: {
    name: 'Glop',
    logo: glop,
    logoStyle: {
      height: 50
    },
    logoSmallStyle: {
      height: 35
    },
    url: 'https://www.glop.es/',
    visible: true
  },
  kevo: {
    name: 'Kevo AI',
    logo: kevo,
    logoStyle: {
      height: 50
    },
    logoSmallStyle: {
      height: 30
    },
    url: 'https://kevo.ai/'
  },
  taxitronic: {
    name: 'Taxitronic',
    logo: taxitronic,
    logoStyle: {
      height: 50,
      position: 'relative',
      top: 10
    },
    logoSmallStyle: {
      height: 30
    },
    url: 'https://www.taxitronic.com/'
  },
  qamarero: {
    name: 'Qamarero',
    logo: qamarero,
    logoStyle: {
      height: 45,
      position: 'relative',
      top: -5
    },
    logoSmallStyle: {
      height: 28
    },
    url: 'https://qamarero.com/',
    visible: true
  },
  paymefy: {
    name: 'Paymefy',
    logo: paymefy,
    logoStyle: {
      height: 60,
      position: 'relative',
      top: -5
    },
    logoSmallStyle: {
      height: 32
    },
    url: 'https://paymefy.com/'
  },
  comercia: {
    name: 'Comercia Global Payments',
    logo: comercia,
    logoStyle: {
      height: 35
    },
    logoSmallStyle: {
      height: 20
    },
    url: 'https://globalpayments.es/'
  },
  hotelgest: {
    name: 'Hotelgest',
    logo: hotelgest,
    logoStyle: {
      height: 60
    },
    logoSmallStyle: {
      height: 40
    },
    url: 'https://hotelgest.com/',
    visible: true
  },
  truust: {
    name: 'Truust',
    logo: truust,
    logoStyle: {
      height: 55
    },
    logoSmallStyle: {
      height: 35
    },
    url: 'https://truust.io/',
    visible: true
  },
  lymon: {
    name: 'Lymon',
    logo: lymon,
    logoStyle: {
      height: 55
    },
    logoSmallStyle: {
      height: 35
    },
    url: 'https://lymon.es/',
    visible: true
  },
  mrm: {
    name: 'MRM',
    logo: mrm,
    logoStyle: {
      height: 65
    },
    logoSmallStyle: {
      height: 45
    },
    url: 'https://mrmsolutions.es/',
    visible: true
  },
  payandtip: {
    name: 'Pay and tip',
    logo: payandtip,
    logoStyle: {
      height: 65
    },
    logoSmallStyle: {
      height: 45
    },
    url: 'https://payandtip.com/'
  }
};

export const usePartners = (promoCode?: string | null) => {
  let promo = promoCode || (qs.parse(window.location.search).promo as string);
  if (promo && (promo.endsWith('text') || promo.endsWith('dev'))) {
    promo = promo.replace(/(text|dev)$/, '');
  }
  const partner = useMemo(() => {
    const result = partners[promo as keyof typeof partners];
    if (!result) return undefined;
    result.code = promo as string;
    return result;
  }, [promo]);
  return [partner, partners] as const;
};
